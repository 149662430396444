import React, { Component } from 'react'
import Menu from './Menu'
//import Menu2 from './Menu2'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Line from './Line'
import Bill from './Bill'
import Conso from './Conso'
import Account from './Account'
import Help from './Help'
import Parameter from './Parameter'
import { Fragment } from 'react'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import line_logo from '../assets/img/ligne.png'
import bill_logo from '../assets/img/factures.png'
import conso_logo from '../assets/img/conso.png'
import account_logo from '../assets/img/compte.png'
import help_logo from '../assets/img/aide.png'
import parameter_logo from '../assets/img/parametres.png'
import NotifService from "../services/notif-service";
import UnpaidBills from "./UnpaidBills"

class HomeLogged extends Component {
  state = {
    line: true,
    bill: false,
    conso: false,
    account: false,
    help: false,
    parameter: false,
    menu_shown: false,
    modalUnpaidShow:false
  }

  componentDidMount(){
    NotifService.getLoginNotif().then(
      (response) => {
        const data = response.data;
        if(data.unpaid_invoice !== 0){
            this.setState({modalUnpaidShow: true});
        }
    }) ;
  }

  handleCloseUnpaidBills = () => {
    this.setState({modalUnpaidShow:false});
  };


  handleListItemClick = (event, index) => {
    var newState = {}
    Object.keys(this.state).map(function (key) {
      return newState[key] = false;
   })
   newState[index] = true
    this.setState( newState );
  };

  handleShowMenu = () => {
    this.setState({menu_shown: true})
  }

  handleDrawerClose = () => {
    this.setState({menu_shown: false})
  }

    render() {
      
        return (
          <Fragment>

          <div className="top-bar-mobile">
            <div className="curr_menu">
            {this.state.line && (
                <div className="mobile_head_menu">
                  <img src={line_logo} alt="Ligne" className="menu_head_img"/><span>Ligne</span>
                </div>
            )}
                {this.state.bill && (
                  <div className="mobile_head_menu">
                    <img src={bill_logo} alt="Factures" className="menu_head_img"/><span>Factures</span>
                </div>
                )}
                {this.state.conso && (
                  <div className="mobile_head_menu">
                    <img src={conso_logo} alt="Conso" className="menu_head_img"/><span>Conso</span>
                </div>
                )}
                {this.state.account && (
                  <div className="mobile_head_menu">
                    <img src={account_logo} alt="Compte" className="menu_head_img"/><span>Compte</span>
                </div>
                )}
                {this.state.help && (
                  <div className="mobile_head_menu">
                    <img src={help_logo} alt="Aide" className="menu_head_img"/><span>Aide</span>
                </div>
                )}
                {this.state.parameter && (
                  <div className="mobile_head_menu">
                    <img src={parameter_logo} alt="Paramètres" className="menu_head_img"/><span>Paramètres</span>
                </div>
                )}
            </div>
          <div className="mobile_menu_icon">
            <Link onClick={() => {this.handleShowMenu()}} underline='none'>
                <MenuIcon className="menu_icon"/>
            </Link>
            </div>
          </div>

          <div className="page-logged">
      
            <div className="content">
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
              <Grid item xs={false} md={3} className='hidden_on_mobile'>
              <Paper>
                <Menu 
                menu_click_action={this.handleListItemClick}
                parent_state={this.state}
                />
              </Paper>
              </Grid>
              <Grid item xs={12} md={9}>
              <Paper>
                {this.state.line && (
                  <Line/>
                )}
                {this.state.bill && (
                  <Bill/>
                )}
                {this.state.conso && (
                  <Conso/>
                )}
                {this.state.account && (
                  <Account/>
                )}
                {this.state.help && (
                  <Help/>
                )}
                {this.state.parameter && (
                  <Parameter/>
                )}
              </Paper>
              </Grid>
            </Grid>
            </div>
            
          </div>
         

          <Drawer
        className=""
        variant="persistent"
        anchor="right"
        open={this.state.menu_shown}
        
      >
        <div className="">
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <Menu menu_click_action={this.handleListItemClick} parent_state={this.state} />
        {/* <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>

          <UnpaidBills modalUnpaidShow={this.state.modalUnpaidShow} handleCloseUnpaidBills={this.handleCloseUnpaidBills}/>

          </Fragment>
        )
    }
}

export default HomeLogged
import React, { Component, Fragment } from 'react'
import HelpService from '../services/help-service'
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Help extends Component {

  state = {open:false}

  componentDidMount(){
    HelpService.getFaq().then(
        (response) => {
          const data = response.data;
          this.setState({faq: data});
         }) ;

  }

  setOpen = (panel) => {
    this.setState({open:panel})
  }

  handleClick = (panel) => (event) => {
    this.setOpen(this.state.open === panel ? false : panel);
  };

    render() {

      var help = []
      if(this.state.faq){
      
        for (var i=0; i < this.state.faq.length; i++) {
          var faqItem = [];
          for(var j=0; j < this.state.faq[i].questions.length; j++){
            faqItem.push(
              <Accordion key={j} expanded={this.state.expanded }>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="bh-content"
                  id="bh-header"
                >
                  <Typography className="faq_question">{this.state.faq[i].questions[j].question}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <Typography className="faq_response">
                  {this.state.faq[i].questions[j].response}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          }
          help.push(
            <Fragment key={i} >
              <ListItem button onClick={this.handleClick(this.state.faq[i].name)}>
                  <ListItemText primary={this.state.faq[i].name} />
                    {this.state.open === this.state.faq[i].name ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.open === this.state.faq[i].name} timeout="auto" unmountOnExit>
                    {faqItem}
                  </Collapse>
            </Fragment>
          )
        }
      }

        return (
          <Fragment>
            { this.state.faq ? (
              <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  FAQ
                </ListSubheader>
              }
            >
            {help}
            </List>

            ) : (
              <CircularProgress className="page_loader"/>
            )}
          </Fragment>
        )
    }
}

export default Help
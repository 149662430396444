import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SimplusLogo from '../assets/img/logo.png'
import { Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SimplusButton from './SimplusButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LineService from "../services/line-service";

class ConfirmNewOffer extends Component {

    state = {
        loading:false,
        modal_open: false,
        modal_title: '',
        modal_text: '',
        loading_newOffer: false
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

      componentDidMount(){
        
      }
    

      handleClose = () => {
        this.setState({modal_open:false,modal_text:'',modal_title:''})
        this.props.handleCloseConfirmOffer();
      };


      handleConfirmOfferPressed = () => {
        this.setState({loading_newOffer:true});

        LineService.switchOffer(this.props.newOfferId,this.props.clientLineId).then(
          (response) => {
            console.log(response);
            //const data = response.data;
            if(response.data.error){
              this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
            }
            else {
              this.setState({modal_open:true,modal_title:'Changement effectué',modal_text:'Votre offre a bien été changée.'})
            }
            this.setState({loading_newOffer: false});
           });
      }

      render(){
        
        var opeInfo = ""
        if (this.props.newOfferOperators.indexOf(this.props.actualNetwork) === -1 ){
          opeInfo = "Cette offre est réservée au réseau "+this.props.newOfferOperators[0]+". Si vous confirmez le changement d'offre votre ligne sera gratuitement basculée sur le réseau "+this.props.newOfferOperators[0]
        }

        return(
            <Fragment>
        <Dialog fullScreen open={this.props.modalConfirmOfferShow} onClose={this.props.handleCloseConfirmOffer} TransitionComponent={this.Transition}>
        <AppBar className="top-bar-modal-offer">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={this.props.handleCloseConfirmOffer} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="">
              SIM+  Confirmation changement d'offre
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile" />
            <Grid item xs={12} sm={12} md={6}>
              <Paper className="offer_cnt">
                <img src={SimplusLogo} alt="logo sim+" className="logo_simplus_offer"/>
                <Typography className="offer-expl">
                  Changement d'offre de la ligne <br/>{ this.props.msisdn } 
                </Typography>


                <Typography className="oldoffer-head">
                  Ancienne offre :
                </Typography>

                <Typography className="oldoffer-name">
                {this.props.actualOffer} 
                </Typography>

                <ArrowDownwardIcon className="arrow_down"/>

                <Typography className="newoffer-name">
                {this.props.newOfferName}
                </Typography>

                <Typography className="newoffer-price">
                {this.props.newOfferPrice} / mois
                </Typography>
                
                <Typography className="newoffer-change-ope-info">
                {opeInfo}
                </Typography>

                <SimplusButton classNameAdd="newoff_conf" button_label="Confirmer" action_onclick={this.handleConfirmOfferPressed} mustReinit={!this.state.loading_newOffer}/>


              </Paper>
            </Grid>
          <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile" />
        </Grid>

      </Dialog>
      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className="modal"
              open={this.state.modal_open}
              onClose={this.handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.modal_open}>
              <div className="paper">
                  <h2 id="transition-modal-title">{this.state.modal_title}</h2>
                  <p id="transition-modal-description">{this.state.modal_text}</p>
                </div>
              </Fade>
            </Modal>
      </Fragment>
      )
      }
}

export default ConfirmNewOffer
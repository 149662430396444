import React, { Component, Fragment } from 'react'
import logo_app_store from '../assets/img/apple-store.png'
import logo_google_play from '../assets/img/google-play.png'

class Footer extends Component {
    render() {
        return (
          <Fragment>
            <div className="stores">
              <div className="appstore">
                <a href="https://apps.apple.com/fr/app/sim/id1544663520" target="_blank" rel="noreferrer">
                  <img src={logo_app_store} alt="SIM+ Apple store"/>
                </a>
              </div>
              <div className="googleplay">
              <a href="https://play.google.com/store/apps/details?id=com.cst.simplus" target="_blank" rel="noreferrer">
                <img src={logo_google_play} alt="SIM+ Google play"/>
              </a>
              </div>
            </div>
            <div className="bottom-bar">
              &copy; SIMPLUS - 2021 
            </div>
          </Fragment>
          
        )
    }
}

export default Footer
import React, { Component } from 'react'
import PhoneIcon from '@material-ui/icons/Phone';
import { Fragment } from 'react';

class Header extends Component {

    render() {
        return (
          <Fragment>
          <div className="top-bar">
            <div className="slogan">
              LA TÉLÉPHONIE POUR TOUS ET AU MEILLEUR PRIX !
            </div>
            <div className="support">
              <span><PhoneIcon/></span>
              <span className="phone_num">01 80 80 44 44</span>
            </div>
          </div>
          </Fragment>
        )
    }
}

export default Header
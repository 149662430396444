import React, { Component, Fragment } from 'react'
import BillService from '../services/bill-service'
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import UnpaidBills from "./UnpaidBills"

class Bill extends Component {

  state = {expanded:false,
          modalOpen:false,
          modalUnpaidShow:false
        }

  componentDidMount(){
    BillService.getBills().then(
        (response) => {
          const data = response.data;
          this.setState({bills: data});
          for(var i=0;i<data.length;i++){
            if(data[i].status === "Impayée"){
              this.setState({modalUnpaidShow: true});
              break;
            }
          }
        
         }) ;

  }

  handleCloseUnpaidBills = () => {
    this.setState({modalUnpaidShow:false});
  };

  setExpanded = (panel) => {
    this.setState({expanded:panel})
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setExpanded(isExpanded ? panel : false);
  };

  handleDwldBill = () => {
    BillService.getBillByName(this.state.expanded).then(
      (response) => {
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
       }).catch(error => {
        console.log(error);
        this.handleOpen();
    }); 
       
  }

  handleOpen = () => {
    this.setState({modalOpen:true})
  };

  handleClose = () => {
    this.setState({modalOpen:false})
  };


    render() {

      var bills = []
      if(this.state.bills){
      
        for (var i=0; i < this.state.bills.length; i++) {
        
          bills.push(<Accordion key={i} expanded={this.state.expanded === this.state.bills[i].name} onChange={this.handleChange(this.state.bills[i].name)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="bh-content"
            id="bh-header"
          >
            <Typography className="bill_date">{this.state.bills[i].date}</Typography>
            <Typography className={ this.state.bills[i].status === "Impayée" ? 'unpaid_bill' : 'paid_bill'}>{this.state.bills[i].status}</Typography>
            <Typography className="bill_amount">{this.state.bills[i].amount} €</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography className="bill_msisdn">
              {this.state.bills[i].name} 
            </Typography>
            <Typography className="bill_offer">
              {this.state.bills[i].offer} 
            </Typography>
            <Link href="#" onClick={() => {this.handleDwldBill()}}>
            <Typography className="bill_dwld">
              <GetAppIcon className="ico_dwld"/> Télécharger
            </Typography>
            </Link>
          </AccordionDetails>
        </Accordion>)
        }
      }

        return (
          <Fragment>
            { this.state.bills ? (
              <div>
                {bills}
              </div>
            ) : (
              <CircularProgress className="page_loader"/>
            )}

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal"
            open={this.state.modalOpen}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.modalOpen}>
            <div className="paper">
                <h2 id="transition-modal-title">Erreur</h2>
                <p id="transition-modal-description">Impossible de récupérer la facture.<br/>Veuillez réessayer plus tard.</p>
              </div>
            </Fade>
          </Modal>

          <UnpaidBills modalUnpaidShow={this.state.modalUnpaidShow} handleCloseUnpaidBills={this.handleCloseUnpaidBills}/>

          </Fragment>
          
        )
    }
}

export default Bill
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BillService from "../services/bill-service";
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SimplusLogo from '../assets/img/logo.png'
//import CBLogo from '../assets/img/CB.jpg'
import { Fragment } from 'react'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
//import Input from '@material-ui/core/Input';
import SimplusButton from './SimplusButton';
//import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "date-fns";
//import DateFnsUtils from "@date-io/date-fns";
//import frLocale from "date-fns/locale/fr";
import {manageCbEntry,manageCvvEntry} from '../Utils';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

class unpaidBills extends Component {

    state = {
        loading:false,
        selectedDate: '',
        modal_open: false,
        modal_title: '',
        modal_text: '',
        numero_cb: '',
        numero_cvv: ''
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

      componentDidMount(){
        this.setState({selectedDate:new Date()})

        BillService.getBills().then(
            (response) => {
              const data = response.data;
              this.setState({unpaidBills: data});
             }) ;

        BillService.getPaymentLink().then(
            (response) => {
              const data = response.data;
              this.setState({payment_url: data.url});
        }) ;
      
      }

      handleChangeField = (event) => {
        const {name, value} = event.target;
        if(name === "numero_cb"){
            const cbEntry = manageCbEntry(value,this.state.lastCbNumOfChar)
            this.setState({lastCbNumOfChar: cbEntry.length, numero_cb: cbEntry})
        }
        else if (name === "numero_cvv"){
          const cvvEntry = manageCvvEntry(value)
          this.setState({numero_cvv: cvvEntry})
        }
        else {
          this.setState( {[name]: value} );
        }
      };


      handleDateChange = (date) => {
        this.setState({selectedDate: date});
      }


      paymentLinkClicked = () => {
        window.open(this.state.payment_url, "_blank")
      }


     /*  payUnpaidPressed = () => {
        this.setState({loading: true});
        
        var month = this.state.selectedDate.getMonth()+1;
        if((month+"").length === 1){
            month = "0"+month;
        }
        const year = this.state.selectedDate.getFullYear()-2000      

        BillService.payUnpaidBills(this.state.numero_cb,this.state.numero_cvv,month,year).then(
            (response) => {
                console.log(response)
              if(response.data.error){
                this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
              }
              else {
                this.setState({modal_open:true,modal_title:'Réglement enregistré',modal_text:'Votre réglement à été enregistré, il sera pris en compte dans les 24 à 48H.'})
              }
              this.setState({loading: false});
             });
      }
 */
      handleClose = () => {
        this.setState({modal_open:false,modal_text:'',modal_title:''})
      };

      render(){
        var unpaidBills = []
        var totalUnpaid = 0
        if(this.state.unpaidBills){
        
          for (var i=0; i < this.state.unpaidBills.length; i++) {
          
            if(this.state.unpaidBills[i].status === "Impayée"){
              const primaryText = <Fragment>
                <Typography className="unpaid-bill-name">{this.state.unpaidBills[i].name}</Typography>
                <Typography className="unpaid-bill-date">{this.state.unpaidBills[i].date}</Typography>
                <Typography className="unpaid-bill-amount">{this.state.unpaidBills[i].amount+" €"}</Typography>
              </Fragment>
  
              totalUnpaid += parseFloat(this.state.unpaidBills[i].amount)
  
              unpaidBills.push(
                <Fragment key={i}>
                  <ListItem>
                    <ListItemText primary={primaryText} />
                  </ListItem>
                  <Divider />
                </Fragment>
              )
            }
          
          }
        }

        return(
            <Fragment>
        <Dialog fullScreen open={this.props.modalUnpaidShow} onClose={this.props.handleCloseUnpaidBills} TransitionComponent={this.Transition}>
        <AppBar className="top-bar-modal-unpaid-bills">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={this.props.handleCloseUnpaidBills} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="">
              SIM+  Factures impayées
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile" />
            <Grid item xs={12} sm={12} md={6}>
              <Paper className="unpaid_cnt">
                <img src={SimplusLogo} alt="logo sim+" className="logo_simplus_unpaid_bills"/>
                <Typography className="unpaid-bill-expl">
                Sauf erreur de notre part, les factures suivantes sont en attente de réglement :
                </Typography>
                <List>
                  {unpaidBills}
                </List>
                <Typography className="unpaid-bill-total">
                Total à régler : {totalUnpaid} €
                </Typography>
                {/* <Typography className="unpaid-bill-write-info">
                Veuillez saisir vos informations de paiement
                </Typography>
                <img src={CBLogo} alt="logo CB" className="logo_cb_unpaid_bills"/>

                <FormControl className="form-tf width_51 mt-2 unpaid_form">
                    <InputLabel htmlFor="standard-adornment-num-cb">Numéro de carte bancaire</InputLabel>
                    <Input
                    id="standard-adornment-num-cb"
                    name="numero_cb"
                    type='text'
                    value={this.state.numero_cb}
                    onChange={this.handleChangeField}
                    />
                </FormControl>
                
                <FormControl className="form-tf width_51 mt-2 unpaid_form">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                        <DatePicker
                          variant="inline"
                          openTo="year"
                          views={["year", "month"]}
                          label="Date d'expiration"
                          value={this.state.selectedDate}
                          onChange={this.handleDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
                        
                <FormControl className="form-tf width_51 mt-2 unpaid_form">
                    <InputLabel htmlFor="standard-adornment-cvv">CVV</InputLabel>
                    <Input
                        id="standard-adornment-cvv"
                        name="numero_cvv"
                        type='text'
                        value={this.state.numero_cvv}
                        onChange={this.handleChangeField}
                    />
                </FormControl>
 */}
                <SimplusButton classNameAdd="pay_button" button_label="Payer" action_onclick={this.paymentLinkClicked} mustReinit={!this.state.loading}/>
                
              </Paper>
              
            </Grid>
          <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile" />
        </Grid>

      </Dialog>
      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className="modal"
              open={this.state.modal_open}
              onClose={this.handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.modal_open}>
              <div className="paper">
                  <h2 id="transition-modal-title">{this.state.modal_title}</h2>
                  <p id="transition-modal-description">{this.state.modal_text}</p>
                </div>
              </Fade>
            </Modal>
      </Fragment>
      )
      }
}

export default unpaidBills
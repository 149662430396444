import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SimplusLogo from '../assets/img/logo.png'
import LoginForm from './LoginForm';
import Onboarding from './Onboarding';
import Register from './Register';

class Home extends Component {
  state = {
    isCreateNewAccount: false,
    isActivateSim: false
  }

  handleCreateAccountClick = () => {
    this.setState({isCreateNewAccount:true,isActivateSim:false});
  }

  handleActivateSimClick = () => {
    this.setState({isCreateNewAccount:false,isActivateSim:true});
  }

    render() {
        return (
          <div className="main-content">
            <div className="home_title">
              <div className="home_title_logo">
                <img src={SimplusLogo} alt="Logo SIM+"/>
              </div>
              <div className="home_title_text">
                <h1>Bienvenue sur votre espace client SIM+</h1>
              </div>
            </div>
            <div className="home_content">
            { this.state.isCreateNewAccount || this.state.isActivateSim ? (
              <Grid container direction="row" justify="center" alignItems="flex-start" spacing={6}>
              <Grid item xs={12} md={12}>
              <Paper>
                <Register is_create_account={this.state.isCreateNewAccount} is_activate_sim={this.state.isActivateSim}/>
              </Paper>
              </Grid>
            </Grid>
            ) : (
              <Grid container direction="row" justify="center" alignItems="flex-start" spacing={6}>
              <Grid item xs={12} md={4}>
              <Paper>
                <LoginForm/>
              </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
              <Paper>
                <Onboarding create_account_action={this.handleCreateAccountClick} activate_sim_action={this.handleActivateSimClick} />
              </Paper>
              </Grid>
            </Grid>
            )}
            </div>
            <div className="old_cc_cnt">
              <a href="https://mon-compte.simplus.fr/" target="_parent">Accèder à l'ancien espace client</a>
            </div>
          </div>
        )
    }
}

export default Home
import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth-service';
import config from "../Config.json";

const API_URL = config.API_URL+'api/bill';

class BillService {

    getBills() {
        return axios.get(API_URL , { headers: authHeader() })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          };
      });
    }

    getBillByName(name) {
      return axios.get(API_URL+"/"+name , { headers: authHeader(), responseType: 'blob' })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        };
    });
  }


  payUnpaidBills(numero_cb,numero_cvv,mois_fv,annee_fv){
  return axios.post(API_URL+"/payUnpaidBills" , { "numero_cb" : numero_cb, "numero_cvv":numero_cvv, "mois_fv":mois_fv, "annee_fv":annee_fv }, { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        }  
        return error.response;
    });
  }


  getPaymentLink(){
    return axios.post(API_URL+"/getPaymentLink" , { }, { headers: authHeader() })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          }  
          return error.response;
      });
    }


    getAddCbLink(){
      return axios.post(API_URL+"/getAddCbLink" , { }, { headers: authHeader() })
          .catch(function (error) {
            if (error.response) {
              if(error.response.status === 401){
                AuthService.logout();
                window.location.reload();
              }
            }  
            return error.response;
        });
      }
  
}

export default new BillService();

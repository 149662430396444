import React, { Component, Fragment } from 'react'
import ConsoService from '../services/conso-service'
import CdrService from '../services/cdr-service'
import LineService from "../services/line-service";
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const VoiceLinearProgress = withStyles((theme) => ({
  root: {
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#F5F5F5',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#283376',
  },
}))(LinearProgress);

const SMSLinearProgress = withStyles((theme) => ({
  root: {
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#F5F5F5',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#d2edff',
  },
}))(LinearProgress);

const DATALinearProgress = withStyles((theme) => ({
  root: {
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#F5F5F5',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#e55f2b',
  },
}))(LinearProgress);

class Conso extends Component {

  state = {
    page:0,
    rowsPerPage:10,
    consos_detail:[],
    lines:[],
    visible_idx:0
  }

  componentDidMount(){

    this.setState({selectedDate: new Date()});
    
    if(!this.state.lines || this.state.lines.length === 0){
      LineService.getInfoV2().then(
        (response) => {
          const data = response.data;
          this.setState({lines: data});

          const today = new Date();
          var month = today.getMonth()+1;
          if((month+"").length === 1){
            month = "0"+month;
          }
          const year = today.getFullYear()

          this.getBucketForPeriod(year,month);

         }) ;
    }

    
    
  }


  getBucketForPeriod(year,month){

    ConsoService.getBucketsV2(this.state.lines[this.state.visible_idx].clientLineId,year+"",month+"").then(
      (response) => {
        const data = response.data;
        this.setState({consos: data});
       }) ;

       
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleDateChange = (date) => {
    if(this.state.selectedDate !== date){
      var month = date.getMonth()+1;
      if((month+"").length === 1){
        month = "0"+month;
      }
      const year = date.getFullYear()
      this.getBucketForPeriod(year,month);
    }
    this.setState({selectedDate: date,consos_detail:[]});
  }

  handleClickDetail = () => {
    var month = this.state.selectedDate.getMonth()+1;
      if((month+"").length === 1){
        month = "0"+month;
      }
      const year = this.state.selectedDate.getFullYear()
    CdrService.getCdrsV2(this.state.lines[this.state.visible_idx].clientLineId,year+"",month+"").then(
      (response) => {
        const data = response.data;
        //console.log(data);
        this.setState({consos_detail: data});
       }) ;
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:+event.target.value,page:0})
  };

  handleChange = (event,newValue) => {      
    this.setState({visible_idx:newValue},this.getBucketAfterStateChange)
  };

  getBucketAfterStateChange = () => {
    if(this.state.selectedDate){
      var month = this.state.selectedDate.getMonth()+1;
      if((month+"").length === 1){
        month = "0"+month;
      }
      const year = this.state.selectedDate.getFullYear()
      this.getBucketForPeriod(year,month);
    }
  }

    render() {

      var consos = []
      if(this.state.consos){
      
        if(this.state.consos.length === 0){
          consos.push(<span key={-1}>Aucune données pour la période séléctionnée</span>)
        }
        else {

          var levelVoix = 0
          var stateVoix = ""

          for (var i=0; i < this.state.consos.length; i++) {
              if(this.state.consos[i].type === 0){
                const consumed = this.state.consos[i].quantity;
                if(consumed < 60) {
                  stateVoix = consumed + " secondes ";
                }
                else if(consumed < 3600) {
                  const nbMin = parseInt(consumed/60);
                  stateVoix = nbMin+" minutes "+(consumed - (nbMin*60))+" secondes ";
                }
                else {
                  const nbHeures = parseInt(consumed/3600);
                  const nbSecLeft = consumed - (nbHeures*3600);
                  const nbMin = parseInt(nbSecLeft/60);
                  stateVoix = nbHeures+" heures "+nbMin+" minutes "+(nbSecLeft - (nbMin*60))+" secondes ";
                }
                if(this.state.consos[i].bucketCapacity.quantity !== "-1.00"){
                    levelVoix = parseInt((consumed / parseFloat(this.state.consos[i].bucketCapacity.quantity)*3600)*100);
                    stateVoix += " sur "+parseInt(this.state.consos[i].bucketCapacity.quantity)+" heures";
                }
                else {
                  stateVoix += "(illimité)";
                }
              }
          }
          consos.push(<div key={i+"_voix_title"} className="title_conso">Voix national</div>)
          consos.push(<div key={i+"_voix_subtitle"} className="subtitle_conso">{stateVoix}</div>)
          consos.push(<VoiceLinearProgress className="conso_progress" key={i+"_voix_progress"} variant="determinate" value={levelVoix}/>)
          
          var stateSMS = ""
          var levelSMS = 0
          for (i=0; i < this.state.consos.length; i++) {
              if(this.state.consos[i].type === 1){
                const consumed = this.state.consos[i].quantity;
                if(this.state.consos[i].bucketCapacity.quantity !== "-1.00"){
                  stateSMS = consumed + " sur "+this.state.consos[i].bucketCapacity.quantity
                  levelSMS = parseInt((consumed/this.state.consos[i].bucketCapacity.quantity)*100);
                }
                else {
                  stateSMS = consumed + " (illimité)"
                }
              }
          }
          
          consos.push(<div key={i+"_sms_title"} className="title_conso">SMS national</div>)
          consos.push(<div key={i+"_sms_subtitle"} className="subtitle_conso">{stateSMS}</div>)
          consos.push(<SMSLinearProgress className="conso_progress" key={i+"_sms_progress"} variant="determinate" value={levelSMS}/>)

          
          var stateData = ""
          var levelData = 0
          for (i=0; i < this.state.consos.length; i++) {
              if(this.state.consos[i].type === 2){
                const consumed = this.state.consos[i].quantity;
                if(this.state.consos[i].bucketCapacity.quantity !== "-1.00"){
                  var consumedString = consumed + " Ko"
                  if(consumed > 1024){
                    consumedString = parseInt(consumed/1024) + " Mo"
                  }
                  stateData = consumedString + " sur "+parseInt(this.state.consos[i].bucketCapacity.quantity)+" Go"
                  levelData = parseInt((consumed/(this.state.consos[i].bucketCapacity.quantity*1024*1024))*100);
                }
                else {
                  stateData = consumed + " (illimité)"
                }
              }
          }

          consos.push(<div key={i+"_data_title"} className="title_conso">Internet national</div>)
          consos.push(<div key={i+"_data_subtitle"} className="subtitle_conso">{stateData}</div>)
          consos.push(<DATALinearProgress className="conso_progress" key={i+"_data_progress"} variant="determinate" value={levelData}/>)

        }
      }

      var linesTab = []
      var linesContent = []
      if(this.state.lines.length > 0){
        for (i=0; i < this.state.lines.length; i++) {
          linesTab.push(
            <Tab key={'tab_'+i} label={this.state.lines[i].msisdn} {...this.a11yProps(i)} />
          )
        }

        linesContent.push(
          <Fragment key={'cnt_'+i}>
            {/* {this.state.visible_idx  === i && ( */}
              <div className="line" role="tabpanel" hidden={this.state.visible_idx !== i} id={`scrollable-auto-tabpanel-${i}`}
              aria-labelledby={`scrollable-auto-tab-${i}`}>
                <div className="conso_cnt">
                {consos}
                </div>
              </div>
            {/* )} */}
          </Fragment>
          )
      }

      var consos_detail = []
      if(this.state.consos_detail){

        for (i=0; i < this.state.consos_detail.length; i++) {
          if(i >= this.state.page * this.state.rowsPerPage && i < this.state.page * this.state.rowsPerPage + this.state.rowsPerPage){
            var nb = "";
            let row = this.state.consos_detail[i];
            if(row.usage === 0 || row.usage === 1)
              nb = row.duration_string
            else if(row.usage === 2)
              nb = row.sms_multiple+""
            else if(row.usage === 3 || row.usage === 5)
              nb = row.data_volume+" Ko"

            var infoSupp = "Code Pays origine : "+row.country_code_client;
            if(row.country_code_called !== "")
              infoSupp += " / Code pays destination : "+row.country_code_called
            if(row.mms_type !== "")
              infoSupp += " / MMS TYPE : "+row.mms_type
            if(row.premium_offer_type !== 0)
              infoSupp += " / Nom service : "+row.premium_service_name

            consos_detail.push(
              <TableRow key={i+"_detail"}>
                <TableCell>
                  {row.datetime}
                </TableCell>
                <TableCell>
                  {row.usage_string}
                </TableCell>
                <TableCell>
                  {nb}
                </TableCell>
                <TableCell>
                {row.correspondant}
                </TableCell>
                <TableCell>
                {infoSupp}
                </TableCell>
              </TableRow>
            )
          }
          
        }          
      }
        

      return (
        <Fragment>
          { this.state.consos ? (
            <Fragment>
              <div>
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  value={this.state.visible_idx}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {linesTab}
                    </Tabs>
              </AppBar>
              <div className="cnt_date_picker">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  label="Choisir la période"
                  value={this.state.selectedDate}
                  onChange={this.handleDateChange}
                />
                </MuiPickersUtilsProvider>
              </div>
              {linesContent}
            </div>       
            
            { this.state.consos_detail.length > 0 ? (
              <div className="conso_detail_cnt">
            <TableContainer className="cdr_tab_cnt">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                      <TableCell>
                        Date et Heure
                      </TableCell>
                      <TableCell>
                        Type usage
                      </TableCell>
                      <TableCell>
                        Durée / Volume / Nombre
                      </TableCell>
                      <TableCell>
                        Correspondant
                      </TableCell>
                      <TableCell>
                        Informations supplémentaires
                      </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consos_detail}
                </TableBody>
              </Table>
              </TableContainer>
              <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={this.state.consos_detail.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count} au total`}
            />
            </div>
            ):
            (
              <div className="cnt_button_detail">
                <Button variant="outlined" size="large" color="primary" onClick={this.handleClickDetail} className="detail_btn">
                  Voir le Détail
                </Button>
            </div>
            )}
            </Fragment>

          ) : (
            <CircularProgress className="page_loader"/>
          )}
        </Fragment>
        
      )
    }
}

export default Conso
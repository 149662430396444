import React, { Component } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import line_logo from '../assets/img/ligne.png'
import bill_logo from '../assets/img/factures.png'
import conso_logo from '../assets/img/conso.png'
import account_logo from '../assets/img/compte.png'
import help_logo from '../assets/img/aide.png'
import parameter_logo from '../assets/img/parametres.png'
import SimplusLogo from '../assets/img/logo.png'

class Menu2 extends Component {

  active = {
    backgroundColor: 'red'
   }

  handleListItemClick = (event, index) => {
   this.props.menu_click_action(event, index)
  };

    render() {
        return (
          <div>
            <img src={SimplusLogo} className="logo_simplus" alt="Logo SIM+"/>
            <List component="nav" aria-label="main">
              <ListItem 
              button 
              selected={this.props.parent_state.line}
              onClick={(event) => this.handleListItemClick(event, 'line')}
              classes={{selected: 'selected_menu_item'}}
              >
                <ListItemIcon>
                  <img src={line_logo} alt="Ligne" className="menu_img"/>
                </ListItemIcon>
                <ListItemText primary="Ligne" />
              </ListItem>
              <ListItem 
              button
              selected={this.props.parent_state.bill}
              onClick={(event) => this.handleListItemClick(event, 'bill')}
              classes={{selected: 'selected_menu_item'}}
              >
                <ListItemIcon>
                  <img src={bill_logo} alt="Factures" className="menu_img"/>
                </ListItemIcon>
                <ListItemText primary="Factures" />
              </ListItem>
              <ListItem 
              button
              selected={this.props.parent_state.conso}
              onClick={(event) => this.handleListItemClick(event, 'conso')}
              classes={{selected: 'selected_menu_item'}}
              >
                <ListItemIcon>
                  <img src={conso_logo} alt="Conso" className="menu_img"/>
                </ListItemIcon>
                <ListItemText primary="Conso" />
              </ListItem>
              <ListItem  
              button
              selected={this.props.parent_state.account}
              onClick={(event) => this.handleListItemClick(event, 'account')}
              classes={{selected: 'selected_menu_item'}}
              >
                <ListItemIcon>
                  <img src={account_logo} alt="Compte" className="menu_img"/>
                </ListItemIcon>
                <ListItemText primary="Compte" />
              </ListItem>
              <ListItem 
              button
              selected={this.props.parent_state.help}
              onClick={(event) => this.handleListItemClick(event, 'help')}
              classes={{selected: 'selected_menu_item'}}
              >
                <ListItemIcon>
                  <img src={help_logo} alt="Aide" className="menu_img"/>
                </ListItemIcon>
                <ListItemText primary="Aide" />
              </ListItem>
              <ListItem 
              button
              selected={this.props.parent_state.parameter}
              onClick={(event) => this.handleListItemClick(event, 'parameter')}
              classes={{selected: 'selected_menu_item'}}
              >
                <ListItemIcon>
                  <img src={parameter_logo} alt="Paramètres" className="menu_img"/>
                </ListItemIcon>
                <ListItemText primary="Paramètres" />
              </ListItem>
            </List>
          </div>
        )
    }
}

export default Menu2
import React, { Component } from 'react'
import SimplusButton from './SimplusButton'

class Onboarding extends Component {
    render() {
        return (
          <div className="onboarding">
            <h2>Déja client ?</h2>
            <p>Vous avez un numéro SIM+ actif et vous souhaitez accéder a votre espace client pour la 1ere fois ?</p>
            <SimplusButton button_label="Créer mon compte" mustReinit={true} action_onclick={this.props.create_account_action}/>
            <h2>Carte SIM reçue ?</h2>
            <p>Vous venez de souscrire à une offre SIM+ et vous avez reçu votre carte SIM ?</p>
            <SimplusButton button_label="Activer ma carte SIM" mustReinit={true} action_onclick={this.props.activate_sim_action}/>
          </div>
        )
    }
}

export default Onboarding
import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth-service';
import config from "../Config.json";

const API_URL = config.API_URL+'api/buckets/';
const API_URL_V2 = config.API_URL+'api/bucketsV2/';

class ConsoService {

    getBuckets(year, month) {
        return axios.get(API_URL+year+"/"+month , { headers: authHeader() })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          };
      });
    }


    getBucketsV2(clientLineId, year, month) {
      return axios.get(API_URL_V2+clientLineId+"/"+year+"/"+month , { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        };
    });
  }
}

export default new ConsoService();


    
   export const  manageCbEntry = function(value,lastCbNumOfChar) {
        var cbEntry = value
        if(lastCbNumOfChar < cbEntry.length){
          if(cbEntry.length === 4){
            cbEntry = cbEntry + " ";
          }
          else if(cbEntry.length === 5 && cbEntry.slice(-1) !== " "){
            cbEntry = cbEntry.substr(0,4)+" "+cbEntry.slice(-1)
          }
          else if(cbEntry.length === 9){
            cbEntry = cbEntry + " ";
          }
          else if(cbEntry.length === 10 && cbEntry.slice(-1) !== " "){
            cbEntry = cbEntry.substr(0,9)+" "+cbEntry.slice(-1)
          }
          else if(cbEntry.length === 14){
            cbEntry = cbEntry + " ";
          }
          else if(cbEntry.length === 15 && cbEntry.slice(-1) !== " "){
            cbEntry = cbEntry.substr(0,14)+" "+cbEntry.slice(-1)
          }
          else if(cbEntry.length > 19){
            cbEntry = cbEntry.substr(0,19)
          }
        }
        else {
          if(cbEntry.length === 5){
            cbEntry = cbEntry.substr(0,4)
          }
          else if(cbEntry.length === 10){
            cbEntry = cbEntry.substr(0,9)
          }
          else if(cbEntry.length === 15){
            cbEntry = cbEntry.substr(0,14)
          }
        }
        return cbEntry
    }
    
    export const  manageCvvEntry =function(value) {
        var cvvEntry = value
        if(cvvEntry.length > 3){
          cvvEntry = cvvEntry.substr(0,3)
        }
        return cvvEntry
    }

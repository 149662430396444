import React, { Component, Fragment } from 'react'
import LineService from "../services/line-service";
import CircularProgress from '@material-ui/core/CircularProgress';
import SimplusLogo from '../assets/img/logo.png';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SimplusButton from './SimplusButton';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import ChooseOffer from './ChooseOffer';
import ConfirmNewOffer from './ConfirmNewOffer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Line extends Component {

    state = {
      loading_get_rio: false,
      modal_open:false,
      modal_title:'',
      modal_text: '',
      lines:[],
      visible_idx:0,
      switch_offer_clicked: false,
      modalOfferShow:false,
      modalConfirmOfferShow:false,
      choosenOfferId:-1,
      choosenOfferName:'',
      choosenOfferPrice:'',
      promoOpen:true
    }

    componentDidMount(){
      if(!this.state.lines || this.state.lines.length === 0){
        LineService.getInfoV2().then(
          (response) => {
            const data = response.data;
           /* var newState = {};
            Object.keys(data).map(function (key) {
              return newState[key] = data[key];
           })*/
            this.setState({lines: data});
           }) ;
      }
    }

    handleCloseOfferList = () => {
      this.setState({modalOfferShow:false,switch_offer_clicked:false});
    };

    handleCloseConfirmOffer = () => {
      this.setState({modalConfirmOfferShow:false});
    };

    handleClose = () => {
      this.setState({modal_open:false,modal_text:'',modal_title:''})
    };
  
    handlePromoOkClose = () => {
      this.setState({promoOpen:false,modalConfirmOfferShow:true,choosenOfferName:"Forfait évasion illimité 30Go 4G/5G",choosenOfferId:69,choosenOfferPrice:"7.99 €",choosenOfferOperators:["Bouygues"]})
    };

    handlePromoKoClose = () => {
      this.setState({promoOpen:false})
    };
  

    getRio = () => {

      this.setState({loading_get_rio: true});

      LineService.getRioV2(this.state.lines[this.state.visible_idx].clientLineId).then(
        (response) => {
          if(response.data.error){
            this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
          }
          else {
            if(response.data.success === "success"){
              this.setState({modal_open:true,modal_title:'SMS envoyé',modal_text:'Vous allez recevoir votre RIO par SMS.'})
            }
            else {
              this.setState({modal_open:true,modal_title:'SMS envoyé',modal_text:response.data.success})
            }
          }
          this.setState({loading_get_rio: false});
         });
    }

    a11yProps(index) {
      return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
    }
    
    
    handleChange = (event,newValue) => {      
      this.setState({visible_idx:newValue})
    };


    handleSwitchOfferClick = () => {
      this.setState({switch_offer_clicked:true,modalOfferShow:true})
    }

    handleNewOfferChoosen = (offerName,offerId,offerPrice,offerOperators) => {
      this.setState({modalOfferShow:false,switch_offer_clicked:false,choosenOfferId:offerId,choosenOfferName:offerName,choosenOfferPrice:offerPrice,choosenOfferOperators:offerOperators},this.openConfirmOffer);
    }


    openConfirmOffer = () => {
      this.setState({modalConfirmOfferShow:true});
    }

    render() {
      
      
      var linesTab = []
      var linesContent = []
      if(this.state.lines.length > 0){
        for (var i=0; i < this.state.lines.length; i++) {
          linesTab.push(
            <Tab key={'tab_'+i} label={this.state.lines[i].msisdn} {...this.a11yProps(i)} />
          )

          var services = []
          if(this.state.lines[i].services.length > 0){
    
            for (var j=0; j < this.state.lines[i].services.length; j++) {
              if(this.state.lines[i].services[j].usage === "VOICE_NAT" 
              || this.state.lines[i].services[j].usage === "SMS_NAT"
              || this.state.lines[i].services[j].usage === "MMS_NAT"
              || this.state.lines[i].services[j].usage === "DATA_NAT")
              services.push(<span key={j}>{this.state.lines[i].services[j].label}</span>)
            }
          }


          linesContent.push(
            <Fragment key={'cnt_'+i}>
              {this.state.visible_idx  === i && (
                <div className="line" role="tabpanel" hidden={this.state.visible_idx !== i} id={`scrollable-auto-tabpanel-${i}`}
                aria-labelledby={`scrollable-auto-tab-${i}`}>
                <div className="msisdn">
                  <div className="num">
                    {this.state.lines[i].msisdn}
                  </div>
                  <div className="brand">
                    <img src={SimplusLogo} alt="Logo SIM+"/>
                  </div>
                </div>
                <Divider light />
                <h2>
                  {this.state.lines[i].offer_name}
                </h2>
                <h3>
                  {this.state.lines[i].engagement}
                </h3>
                <div className="switch_offer">
                <Button variant="contained" color="primary" onClick={this.handleSwitchOfferClick} >
                  Changer d'offre
                </Button>
                </div>
                <div className="offer_price">
                  {this.state.lines[i].offer_price} &euro; / Mois
                </div>
                <div className="offer_details">
                  {services}
                </div>
                <Divider light />
                <div className="sim">
                  <div className="title">
                    Réseau
                  </div>
                  <div className="num">
                  {this.state.lines[i].network}
                  </div>
                </div>
                <Divider light />
                <div className="sim">
                  <div className="title">
                    SIM
                  </div>
                  <div className="num">
                  {this.state.lines[i].sim}
                  </div>
                </div>
                <Divider light />
                <div className="sim">
                  <div className="title">
                    
                  </div>
                  <div className="num">
                  <SimplusButton button_label="Obtenir mon RIO" action_onclick={this.getRio} mustReinit={!this.state.loading_get_rio}/>
                  </div>
                </div>
                </div>
              )}
             </Fragment>

          )
        }
      }
        return (
          <Fragment>
            { this.state.lines.length > 0 ? (
            <div>
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  value={this.state.visible_idx}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {linesTab}
                    </Tabs>
              </AppBar>
              {linesContent}
            </div>            
            ) : (
              <CircularProgress className="page_loader"/>
             )} 
  
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={this.state.modal_open}
                onClose={this.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.modal_open}>
                <div className="paper">
                    <h2 id="transition-modal-title">{this.state.modal_title}</h2>
                    <p id="transition-modal-description">{this.state.modal_text}</p>
                  </div>
                </Fade>
              </Modal>


              { this.state.switch_offer_clicked ? (
                <ChooseOffer 
                  newOfferChoosen={this.handleNewOfferChoosen} 
                  actualOffer={this.state.lines[this.state.visible_idx].offer_name} 
                  actualNetwork={this.state.lines[this.state.visible_idx].network} 
                  msisdn={this.state.lines[this.state.visible_idx].msisdn} 
                  modalOfferShow={this.state.modalOfferShow} 
                  handleCloseOfferList={this.handleCloseOfferList}/>
              ) : ('')}

              { this.state.modalConfirmOfferShow ? (
                <ConfirmNewOffer
                  newOfferChoosen={this.handleNewOfferChoosen} 
                  actualOffer={this.state.lines[this.state.visible_idx].offer_name} 
                  actualNetwork={this.state.lines[this.state.visible_idx].network} 
                  newOfferName={this.state.choosenOfferName}
                  newOfferId={this.state.choosenOfferId}
                  newOfferPrice={this.state.choosenOfferPrice}
                  newOfferOperators={this.state.choosenOfferOperators}
                  clientLineId={this.state.lines[this.state.visible_idx].clientLineId}
                  msisdn={this.state.lines[this.state.visible_idx].msisdn} 
                  modalConfirmOfferShow={this.state.modalConfirmOfferShow} 
                  handleCloseConfirmOffer={this.handleCloseConfirmOffer}/>
              ) : ('')}

              { this.state.lines.length > 0 && this.state.lines[this.state.visible_idx].offer_name === "Forfait évasion illimité 20Go" && (
                  <Dialog
                  open={this.state.promoOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.handlePromoKoClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>{"SIM+ récompense votre fidélité"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    Bénéficiez du tout nouveau forfait 4G/5G 30Go à 7.99€. Offre valable jusqu'au 15/07/2022 réservée exclusivement aux clients SIM+
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handlePromoOkClose}>J'en profite</Button>
                    <Button onClick={this.handlePromoKoClose}>Non merci</Button>
                  </DialogActions>
                </Dialog>
              )}
          </Fragment>
        )
    }
}

export default Line
import React, { Component, Fragment } from 'react'
import UserService from '../services/user-service'
import CircularProgress from '@material-ui/core/CircularProgress';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import SimplusButton from './SimplusButton';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "date-fns";
//import DateFnsUtils from "@date-io/date-fns";
//import frLocale from "date-fns/locale/fr";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {manageCbEntry,manageCvvEntry} from '../Utils';
import BillService from "../services/bill-service";

class Account extends Component {

  state = {
    modal_open:false,
    modal_title:'',
    modal_text: '',
    isEditingContact: false,
    isEditingPostalAddress:false,
    isEditingPaymentMode: false,
    loading_contact_info:false,
    loading_postal_address:false,
    loading_payment_mode: false,
    email:'',
    password:'',
    address:'',
    addressComp:'',
    zip:'',
    city:'',
    iban: '',
    bic: '',
    numero_cb: '',
    cb: '',
    numero_cvv: '',
    mois_fv: '',
    annee_fv: '',
    expiration: '',
    selectedDate: '',
    lastCbNumOfChar: 0,
    add_cb_url: ''
  }

  componentDidMount(){
    UserService.getInfos().then(
        (response) => {
          const data = response.data;
          this.setState({user: data});
          this.setState({
            email:this.state.user.email,
            phone:this.state.user.phone,
            address:this.state.user.address,
            addressComp:this.state.user.addressComp,
            zip:this.state.user.zip,
            city:this.state.user.city,
            payment_type: this.state.user.payment_type,
            selectedDate:new Date()
          })
          if(this.state.user.payment_type === 1)
            this.setState({iban: this.state.user.iban, bic: this.state.user.bic})
          
          if(this.state.user.payment_type === 2)
            this.setState({cb: this.state.user.cb, expiration: this.state.user.expiration})
         }) ;

  }

  handleClose = () => {
    this.setState({modal_open:false,modal_text:'',modal_title:''})
  };

  handleChangeField = (event) => {
    const {name, value} = event.target;
    if(name === "numero_cb"){
        const cbEntry = manageCbEntry(value)
        this.setState({lastCbNumOfChar: cbEntry.length, numero_cb: cbEntry})
    }
    else if (name === "numero_cvv"){
      const cvvEntry = manageCvvEntry(value)
      this.setState({numero_cvv: cvvEntry})
    }
    else {
      this.setState( {[name]: value} );
    }
  };

  handleChangeContactInfoPressed = () => {
    this.setState( {isEditingContact: true, isEditingPaymentMode:false, isEditingPostalAddress:false, scrollPosition: window.pageYOffset} );
  }

  updateContactInfoPressed = () => {

    this.setState({loading_contact_info: true});

    UserService.changeContactInfo(this.state.email,this.state.phone).then(
      (response) => {
        if(response.data.error){
          this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
        }
        else {
          this.setState({modal_open:true,modal_title:'Informations mises à jour',modal_text:'Vos nouvelles informations de contact ont bien été enregistrées.'})
          this.setState({user: {
            email : this.state.email,
            phone : this.state.phone
          }});
        }
        this.setState({loading_contact_info: false});
       });

    this.setState( {isEditingContact: false} );
  }

  handleChangePostalAddressPressed = () => {
    this.setState( {isEditingPostalAddress: true, isEditingPaymentMode:false, isEditingContact:false, scrollPosition: window.pageYOffset} );
  }

  updatePostalAddressPressed = () => {

    this.setState({loading_postal_address: true});

    UserService.changePostalAddress(this.state.address,this.state.addressComp,this.state.zip,this.state.city).then(
      (response) => {
        if(response.data.error){
          this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
        }
        else {
          this.setState({modal_open:true,modal_title:'Adresse mise à jour',modal_text:'Votre nouvelle adresse a bien été enregistrée.'})
          this.setState({user: {
            address : this.state.address,
            addressComp : this.state.addressComp,
            zip : this.state.zip,
            city : this.state.city,
          }});
        }
        this.setState({loading_postal_address: false});
       });

    this.setState( {isEditingPostalAddress: false, scrollPosition: window.pageYOffset} );
  }

  handleChangePaymentModePressed = () => {
    this.setState( {isEditingPaymentMode: true, isEditingPostalAddress:false, isEditingContact:false, scrollPosition: window.pageYOffset} );
  }

  updatePaymentModePressed = () => {

    this.setState({loading_payment_mode: true});

    var param1,param2,param3,param4;
    if(this.state.payment_type === 1){//pre
        param1 = this.state.iban
        param2 = this.state.bic


    UserService.changePaymentInfo(this.state.payment_type,param1,param2,param3,param4).then(
      (response) => {
        if(response.data.error){
          this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
        }
        else {
          this.setState({modal_open:true,modal_title:'Moyen de paiement mis à jour',modal_text:'Votre nouveau moyen de paiement a bien été enregistré.'})
          
          if(this.state.payment_type === 1){//pre
            this.setState({user: {
              iban : this.state.iban,
              bic : this.state.bic,
              payment_type : this.state.payment_type ,
              cb : '',
              expiration : ''
            }});
          }
          else if(this.state.payment_type === 2){//cb
            this.setState({user: {
              iban : '',
              bic : '',
              payment_type : this.state.payment_type ,
              cb : this.state.numero_cb.substr(0,4)+"XXXXXXXX"+this.state.numero_cb.slice(-4),
              expiration : param2+"/"+param3.slice(-2)
            }});
          }
        }
        this.setState({loading_payment_mode: false});
       });
    this.setState( {isEditingPaymentMode: false, scrollPosition: window.pageYOffset} );
    }
    else if(this.state.payment_type === 2){//cb
      /*param1 = this.state.numero_cb
      param2 = this.state.selectedDate.getMonth()+1;
      if((param2+"").length === 1){
        param2 = "0"+param2;
      }
      param3 = this.state.selectedDate.getFullYear()      
      param4 = this.state.numero_cvv*/

      if(this.state.add_cb_url === ''){
        BillService.getAddCbLink().then(
          (response) => {
            const data = response.data;
            this.setState({add_cb_url: data.url});
            window.open(this.state.add_cb_url, "_blank")
            window.location.reload()
        }) ;
      }
      else{
        window.open(this.state.add_cb_url, "_blank")
        window.location.reload()
      }

    }

  }

  handleChangePaymentMode = (event) => {
      
    if(event.target.value === 2){
      
      if(this.state.add_cb_url === ''){
        BillService.getAddCbLink().then(
          (response) => {
            const data = response.data;
            this.setState({add_cb_url: data.url});
            window.open(this.state.add_cb_url, "_blank")
            window.location.reload()
        }) ;
      }
      else{
        window.open(this.state.add_cb_url, "_blank")
        window.location.reload()
      }
    }
    
    this.setState({payment_type:event.target.value});

  }

  handleDateChange = (date) => {
    this.setState({selectedDate: date});
  }

    render() {
      window.scrollTo(0, this.state.scrollPosition);

        return (
          <Fragment>
            { this.state.user ? (
              <div className="cnt_account">
                <div className="account_cat"><PermIdentityIcon/><span>Votre identité</span></div>
                <div className="acc_data">{this.state.user.firstname} {this.state.user.lastname}</div>
                <Divider light className="account_divider"/>
                <div className="account_cat"><ContactMailIcon/><span>Vos moyens de contact</span></div>
                <div className="acc_inline_cnt">
                  <div className="acc_inl_title">Adresse email</div>
                  <div className="acc_inl_data">
                    { this.state.isEditingContact ? 
                    (
                      <FormControl className="form-tf">
                        <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                        <Input
                          id="standard-adornment-email"
                          name="email"
                          type='text'
                          value={this.state.email}
                          onChange={this.handleChangeField}
                        />
                      </FormControl>
                    ) : (
                      <Fragment>
                      {this.state.email}
                      </Fragment>
                    )}
                    
                  </div>
                </div>
                <div className="acc_inline_cnt">
                  <div className="acc_inl_title">Téléphone</div>
                  <div className="acc_inl_data">
                    
                  { this.state.isEditingContact ? 
                    (
                      <FormControl className="form-tf">
                        <InputLabel htmlFor="standard-adornment-phone">Téléphone</InputLabel>
                        <Input
                          id="standard-adornment-phone"
                          name="phone"
                          type='text'
                          value={this.state.phone}
                          onChange={this.handleChangeField}
                        />
                      </FormControl>
                    ) : (
                      <Fragment>
                        {this.state.phone}
                      </Fragment>
                    )}
                    </div>
                </div>
                <div className="btn_upd">
                { this.state.isEditingContact ? 
                    (
                      <SimplusButton button_label="Mettre à jour" action_onclick={this.updateContactInfoPressed} mustReinit={!this.state.loading_contact_info}/>
                    ):(
                      <Link className="cursor_pointer" onClick={() => {this.handleChangeContactInfoPressed()}} underline='none'>
                        <EditIcon className="button_ico"/>Modifier
                      </Link>
                    )}
                </div>
                <Divider light className="account_divider"/>
                <div className="account_cat"><HomeIcon/><span>Votre adresse postale</span></div>
                <div className="acc_data">
                { this.state.isEditingPostalAddress ? (
                  <Fragment>
                    <FormControl className="form-tf width_51">
                    <InputLabel htmlFor="standard-adornment-address">Adresse</InputLabel>
                    <Input
                      id="standard-adornment-address"
                      name="address"
                      type='text'
                      value={this.state.address}
                      onChange={this.handleChangeField}
                    />
                  </FormControl>
                  <FormControl className="form-tf width_51 mt-2">
                  <InputLabel htmlFor="standard-adornment-addressComp">Complément d'adresse</InputLabel>
                  <Input
                    id="standard-adornment-addressComp"
                    name="addressComp"
                    type='text'
                    value={this.state.addressComp ? this.state.addressComp : ' ' }
                    onChange={this.handleChangeField}
                  />
                </FormControl>
              </Fragment>
                ) : (
                  <Fragment>
                    {this.state.address}
                  </Fragment>
                )}
                </div>
                <div className="acc_data2">
                { this.state.isEditingPostalAddress ? (
                    <Fragment>
                      <FormControl className="form-tf width_51 mt-2">
                      <InputLabel htmlFor="standard-adornment-zip">Code postal</InputLabel>
                      <Input
                        id="standard-adornment-zip"
                        name="zip"
                        type='text'
                        value={this.state.zip}
                        onChange={this.handleChangeField}
                      />
                    </FormControl>
                    <FormControl className="form-tf width_51 mt-2">
                    <InputLabel htmlFor="standard-adornment-city">Ville</InputLabel>
                    <Input
                      id="standard-adornment-city"
                      name="city"
                      type='text'
                      value={this.state.city}
                      onChange={this.handleChangeField}
                    />
                  </FormControl>
                  </Fragment>
                ):(
                  <Fragment>
                    {this.state.zip} {this.state.city}
                  </Fragment>
                )}
                </div>
                <div className="btn_upd">
                { this.state.isEditingPostalAddress ? 
                    (
                      <SimplusButton button_label="Mettre à jour" action_onclick={this.updatePostalAddressPressed} mustReinit={!this.state.loading_postal_address}/>
                    ):(
                      <Link className="cursor_pointer" onClick={() => {this.handleChangePostalAddressPressed()}} underline='none'>
                        <EditIcon className="button_ico"/>Modifier
                      </Link>
                )}

                </div>
                <Divider light className="account_divider"/>
                <div className="account_cat"><CreditCardIcon/><span>Votre moyen de paiement</span></div>

                { this.state.isEditingPaymentMode ? 
                    (
                      <Fragment>
                        <FormControl className="width_51 mt-2">
                        <InputLabel id="select-payment-mode-label">Mode de paiement</InputLabel>
                        <Select
                          labelId="select-payment-mode-label"
                          id="select-payment-mode-label"
                          name="payment_type"
                          value={this.state.payment_type}
                          onChange={this.handleChangePaymentMode}
                        >
                          <MenuItem value={1}>Prélévement bancaire</MenuItem>
                          <MenuItem value={2}>Carte bancaire</MenuItem>
                        </Select>
                      </FormControl>
                      {this.state.payment_type === 1 ? (
                        <Fragment>
                          <FormControl className="form-tf width_51 mt-2">
                            <InputLabel htmlFor="standard-adornment-iban">IBAN</InputLabel>
                            <Input
                              id="standard-adornment-iban"
                              name="iban"
                              type='text'
                              value={this.state.iban}
                              onChange={this.handleChangeField}
                            />
                          </FormControl>
                          <FormControl className="form-tf width_51 mt-2">
                            <InputLabel htmlFor="standard-adornment-bic">BIC</InputLabel>
                            <Input
                              id="standard-adornment-bic"
                              name="bic"
                              type='text'
                              value={this.state.bic}
                              onChange={this.handleChangeField}
                            />
                          </FormControl>
                        </Fragment>
                      ) : ''}
                      {this.state.payment_type === 2 ? (
                        /*{ <Fragment>
                        <FormControl className="form-tf width_51 mt-2">
                          <InputLabel htmlFor="standard-adornment-num-cb">Numéro de carte bancaire</InputLabel>
                          <Input
                            id="standard-adornment-num-cb"
                            name="numero_cb"
                            type='text'
                            value={this.state.numero_cb}
                            onChange={this.handleChangeField}
                          />
                        </FormControl>
                        <FormControl className="form-tf width_51 mt-2">
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                        <DatePicker
                          variant="inline"
                          openTo="year"
                          views={["year", "month"]}
                          label="Date d'expiration"
                          value={this.state.selectedDate}
                          onChange={this.handleDateChange}
                        />
                        </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl className="form-tf width_51 mt-2">
                          <InputLabel htmlFor="standard-adornment-cvv">CVV</InputLabel>
                          <Input
                            id="standard-adornment-cvv"
                            name="numero_cvv"
                            type='text'
                            value={this.state.numero_cvv}
                            onChange={this.handleChangeField}
                          />
                        </FormControl>
                      </Fragment> }*/
                      '') : ''}
                      </Fragment>
                      
                      ):(
                        <Fragment>
                          { this.state.user.payment_type === 1 ? (
                          <div className="acc_data">Prélévement bancaire</div>
                          ):''}
                          { this.state.user.payment_type === 2 ? (
                              <div className="acc_data">Carte bancaire</div>
                          ):''}  
                          { this.state.user.payment_type === 3 ? (
                              <div className="acc_data">Chèque</div>
                          ):''}
                          { this.state.user.payment_type === 0 ? (
                              <div className="acc_data">Inconnu</div>
                          ):''}
                          </Fragment>
                ) }
                
                { !this.state.isEditingPaymentMode ? 
                    (
                      <Fragment>
                        { this.state.user.payment_type === 1 ? (
                          <Fragment>
                          <div className="acc_inline_cnt">
                            <div className="acc_inl_title">IBAN</div>
                            <div className="acc_inl_data">{this.state.iban}</div>
                          </div>
                          <div className="acc_inline_cnt">
                          <div className="acc_inl_title">BIC</div>
                          <div className="acc_inl_data">{this.state.bic}</div>
                          </div>
                          </Fragment>
                          ):''}
                          { this.state.user.payment_type === 2 ? (
                             <Fragment>
                             <div className="acc_inline_cnt">
                               <div className="acc_inl_title">Numéro carte bancaire</div>
                               <div className="acc_inl_data">{this.state.cb}</div>
                             </div>
                             <div className="acc_inline_cnt">
                             <div className="acc_inl_title">expiration</div>
                             <div className="acc_inl_data">{this.state.expiration}</div>
                             </div>
                             </Fragment>
                          ):''}  
                      </Fragment>
                      ):''
                }
                
                <div className="btn_upd">
                { this.state.isEditingPaymentMode ? 
                    (
                      <SimplusButton button_label="Mettre à jour" action_onclick={this.updatePaymentModePressed} mustReinit={!this.state.loading_payment_mode}/>
                    ):(
                      <Link className="cursor_pointer" onClick={() => {this.handleChangePaymentModePressed()}} underline='none'>
                        <EditIcon className="button_ico"/>Modifier
                      </Link>
                )}
                </div>

              </div>
            ) : (
              <CircularProgress className="page_loader"/>
            )}

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className="modal"
              open={this.state.modal_open}
              onClose={this.handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.modal_open}>
              <div className="paper">
                  <h2 id="transition-modal-title">{this.state.modal_title}</h2>
                  <p id="transition-modal-description">{this.state.modal_text}</p>
                </div>
              </Fade>
            </Modal>

          </Fragment>
        )
    }
}

export default Account
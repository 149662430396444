import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SimplusLogo from '../assets/img/logo.png'
import { Fragment } from 'react'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import OfferService from '../services/offer-service';
import Button from '@material-ui/core/Button';

class ChooseOffer extends Component {

    state = {
        loading:false,
        offers: []
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

      componentDidMount(){
        OfferService.getOfferList().then(
          (response) => {
            const data = response.data;
            this.setState({offers: data});
           }) ;
      }
    

      render(){
        var offers = []
        if(this.state.offers.length > 0){
        
          for (var i=0; i < this.state.offers.length; i++) {

            var services = []
            if(this.state.offers[i].offer_services && this.state.offers[i].offer_services.length > 0){
      
              for (var j=0; j < this.state.offers[i].offer_services.length; j++) {
                if(this.state.offers[i].offer_services[j].usage === "VOICE_NAT" 
                || this.state.offers[i].offer_services[j].usage === "SMS_NAT"
                || this.state.offers[i].offer_services[j].usage === "MMS_NAT"
                || this.state.offers[i].offer_services[j].usage === "DATA_NAT")
                services.push(<span className="offer_svc_cnt" key={j}>{this.state.offers[i].offer_services[j].label}</span>)
              }
            }


            var classN = '';
            var btnClass = 'offer-choose-btn';
            var reason = '';
              if(this.props.actualOffer === this.state.offers[i].offer_name) {
                classN = "overlay_curr_offer";
                btnClass = "offer-choose-btn no_display"; 
                reason = "OFFRE ACTUELLE";
              }
              else if (this.state.offers[i].offer_operators.indexOf(this.props.actualNetwork) === -1 ){
                //classN = "overlay_curr_offer";
                //btnClass = "offer-choose-btn no_display"; 
                var opeList = "";
                for(var k=0;k<this.state.offers[i].offer_operators.length;k++){
                    if(k>0){
                      opeList += ", ";
                    }
                    opeList += this.state.offers[i].offer_operators[k];
                } 

                if(this.state.offers[i].offer_operators.length > 1){
                  reason = "RÉSEAUX "+opeList+" UNIQUEMENT";
                }
                else {
                  reason = "RÉSEAU "+opeList+" UNIQUEMENT";
                }

              }
              let offerName = this.state.offers[i].offer_name;
              let offerId = this.state.offers[i].offer_id;
              let offerPrice = this.state.offers[i].offer_price;
              let offerOperators = this.state.offers[i].offer_operators;
      
              const primaryText = <Fragment>
                <Typography className="offer-name">{offerName}</Typography>
                <Typography className="offer-amount">{offerPrice} / mois</Typography>
                <Typography className="offer-details">{services}</Typography>
                <Typography className={btnClass}>
                  <Button variant="contained" color="primary" className="simplus_button" onClick={() => this.props.newOfferChoosen(offerName,offerId,offerPrice,offerOperators)}>
                    Choisir
                  </Button> 
                </Typography>
              </Fragment>
              
              var reasonTextElement = "";
              if(reason !== ''){
                reasonTextElement = <Typography className="reason_text_elt">{reason}</Typography>
              }

              offers.push(
                <Fragment key={i}>
                  <ListItem className={classN}>
                    {reasonTextElement}
                    <ListItemText primary={primaryText} />
                  </ListItem>
                  <Divider />
                </Fragment>
              )
          }
        }

        return(
            <Fragment>
        <Dialog fullScreen open={this.props.modalOfferShow} onClose={this.props.handleCloseOfferList} TransitionComponent={this.Transition}>
        <AppBar className="top-bar-modal-offer">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={this.props.handleCloseOfferList} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="">
              SIM+  Liste des offres
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile" />
            <Grid item xs={12} sm={12} md={6}>
              <Paper className="offer_cnt">
                <img src={SimplusLogo} alt="logo sim+" className="logo_simplus_offer"/>
                <Typography className="offer-expl">
                  Selectionnez une nouvelle offre pour la ligne<br/>{ this.props.msisdn } 
                </Typography>
                <List>
                  {offers}
                </List>         
              </Paper>
              
            </Grid>
          <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile" />
        </Grid>

      </Dialog>
      
      </Fragment>
      )
      }
}

export default ChooseOffer
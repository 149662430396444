import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


class SimplusButton extends Component {

  state = {
    hasClicked: !this.props.mustReinit
  }


    handleClick = () => {
      this.setState({hasClicked: true})
      this.props.action_onclick();
    }


    static getDerivedStateFromProps(nextProps, prevState){
      if(nextProps.mustReinit!==prevState.mustReinit){
        return { hasClicked: !nextProps.mustReinit};
     }
     else return null;
   }
   

    render() {
        return (
            <Fragment>
             { !this.state.hasClicked ? (
              <Button variant="contained" color="primary" className={"simplus_button "+this.props.classNameAdd} onClick={this.handleClick}>
                {this.props.button_label}
              </Button> 
              ) : (
              <CircularProgress className="pressed_button_loader"/>
              )}
            </Fragment>
            
        )
    }
}

export default SimplusButton
import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth-service';
import config from "../Config.json";

const API_URL = config.API_URL+'api/line';

class LineService {

    getInfo() {
        return axios.get(API_URL , { headers: authHeader() })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          };
      });
    }


    getInfoV2() {
      return axios.get(API_URL+"V2" , { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        };
    });
  }

    getRio() {
      return axios.get(API_URL+"/rio" , { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        };
    });
  }

  getRioV2(clientLineId) {
    return axios.get(API_URL+"V2/rio/"+clientLineId , { headers: authHeader() })
    .catch(function (error) {
      if (error.response) {
        if(error.response.status === 401){
          AuthService.logout();
          window.location.reload();
        }
      };
  });
}

  switchOffer(offerId,clientLineId) {
    return axios.post(API_URL+"V2/switch_offer" , { "client_line_id" : clientLineId, "offer_id" : offerId}, { headers: authHeader() })
    .then(response => {
      return response;
    })
    .catch(function (error) {
      if (error.response) {
        if(error.response.status === 401){
          AuthService.logout();
          window.location.reload();
        }
      }  
      return error.response;
  });
}
  


}

export default new LineService();

import React, { Component } from 'react'
import Footer from './Footer'
import Header from './Header'
import AuthService from "../services/auth-service";
import Home from './Home';
import HomeLogged from './HomeLogged';

class Page extends Component {

  state = {
    currentUser: undefined
  };

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

    render() {
        return (
          <div>
            <Header />
            {this.state.currentUser ? (
              <HomeLogged />
            ) : (
              <Home />
            )}
            <Footer/>
          </div>
        )
    }
}

export default Page
import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth-service';
import config from "../Config.json";

const API_URL = config.API_URL+'api/parameter';

class ParameterService {

    changeEmail(email) {
        return axios.put(API_URL+"/email" , { "email" : email}, { headers: authHeader() })
        .then(response => {
          if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response;
        })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          }  
          return error.response;
      });
    }

    changePassword(old_password,new_password,verify_password) {
      return axios.put(API_URL+"/password" , 
      { "old_password" : old_password, 
        "new_password" : new_password,
        "verify_password" : verify_password
      }
      , { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        };
        return error.response;
    });
  }
}

export default new ParameterService();

import axios from "axios";
import config from "../Config.json";

const API_URL = config.API_URL+"api/login_check";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL, 
        { "email" : username,"password" : password },
        { headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
            }      
        })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
    
      
  }

  logout() {
    localStorage.removeItem("user");
  }

  /*register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }*/

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
import React, { Component } from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import OnboardingService from "../services/onboarding-service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


class Register extends Component {
  state = {
    isCreateNewAccount: this.props.is_create_account,
    isActivateSim: this.props.is_activate_sim,
    modal_open: false,
    modal_title: '',
    modal_text: '',
    activeStep:0,
    loading: false,
    phone:'',
    verifCode: '',
    email: '',
    password: '',
    showPassword: false
  }

  handleClose = () => {
    this.setState({modal_open:false,modal_text:'',modal_title:''})
  };


  handleNext = () => {
    this.setState({
      loading: true
    });
    if(this.state.activeStep === 0){
      if(this.state.isCreateNewAccount){
          //Check MSISDN
          OnboardingService.checkMsisdn(this.state.phone).then(
            (response) => {
              console.log(response)

              if(response.data.error){
                this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
              }
              else {
                this.setState({activeStep : this.state.activeStep+1})
              }
              this.setState({
                loading: false
              });
            }
          );
      }
      else if(this.state.isActivateSim){
          //Check SIM
          OnboardingService.checkSim(this.state.phone).then(
            (response) => {
              console.log(response)
              if(response.data.error){
                this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
              }
              else {
                this.setState({activeStep : this.state.activeStep+1})
              }
              this.setState({
                loading: false
              });
            }
          );
      }
    }
    else if(this.state.activeStep === 1){
      if(this.state.isCreateNewAccount || this.state.isActivateSim){
        //Check check_verification_code
        OnboardingService.checkVerificationCode(this.state.phone,this.state.verifCode).then(
          (response) => {
            console.log(response)

            if(response.data.error){
              this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
            }
            else {
              this.setState({activeStep : this.state.activeStep+1, email: response.data.email})
            }
            this.setState({
              loading: false
            });
          }
        );
      }
    }
    else if(this.state.activeStep === 2){
      if(this.state.isCreateNewAccount || this.state.isActivateSim){
        //create credential
        OnboardingService.createCredentials(this.state.phone,this.state.verifCode,this.state.email,this.state.password).then(
          (response) => {
            console.log(response)

            if(response.token){
              this.setState({activeStep : this.state.activeStep+1})
              window.location.reload();
            }
            else {
              this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
            }
            this.setState({
              loading: false
            });
          }
        );
      }
    }

  };

  handleBack = () => {
    this.setState({activeStep :  this.state.activeStep-1})
  };

  handleReset = () => {
    this.setState({activeStep : 0})
  };

  handleChangeField = (event) => {
    const {name, value} = event.target;
    this.setState( {[name]: value} );
  }

  handleClickShowPassword = () => {
    const st = {...this.state};
    st.showPassword = !st.showPassword ;
    this.setState( st );
  };
  
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  getSteps() {
    if(this.state.isCreateNewAccount)
      return ['Numéro mobile SIM+', 'Vérification SMS', 'Créer vos identifiants'];
    else if(this.state.isActivateSim)
      return ['Carte SIM ', 'Vérification email', 'Créer vos identifiants'];
    else return []
  }
  
  getStepContent(step) {
    var jsxElt = []
    switch (step) {
      case 0:
        if(this.state.isCreateNewAccount){
          jsxElt.push(
            <div key={"cre0"}>
            <Typography>Veuillez renseigner votre numéro de téléphone mobile SIM+</Typography>
            <FormControl className="form-tf width-200 mt-2 mabo-2">
              <InputLabel htmlFor="standard-adornment-phone">Mobile SIM+</InputLabel>
              <Input
                id="standard-adornment-phone"
                name="phone"
                type='text'
                value={this.state.phone}
                onChange={this.handleChangeField}
              />
              </FormControl>
            </div>
          )
        }
        else if(this.state.isActivateSim){
          jsxElt.push(
            <div key={"act0"}>
            <Typography>Veuillez saisir les 5 derniers caractères du numéro de série de votre carte SIM</Typography>
            <FormControl className="form-tf width-250 mt-2 mabo-2">
              <InputLabel htmlFor="standard-adornment-sim">5 derniers numéro serie de la SIM</InputLabel>
              <Input
                id="standard-adornment-sim"
                name="phone"
                type='text'
                value={this.state.phone}
                onChange={this.handleChangeField}
              />
              </FormControl>
            </div>
          ) 
        }

        return jsxElt;
      case 1:
        if(this.state.isCreateNewAccount){
          jsxElt.push(
            <div key={"cre1"}>
            <Typography>Veuillez renseigner le code de vérification reçu par SMS</Typography>
            <FormControl className="form-tf width-200 mt-2 mabo-2">
              <InputLabel htmlFor="standard-adornment-verif-code">Code de vérification</InputLabel>
              <Input
                id="standard-adornment-verif-code"
                name="verifCode"
                type='text'
                value={this.state.verifCode}
                onChange={this.handleChangeField}
              />
              </FormControl>
            </div>
          )
        }
        else if(this.state.isActivateSim){
          jsxElt.push(
            <div key={"act1"}>
            <Typography>Veuillez renseigner le code de vérification reçu par Email (email utilisé lors de votre inscription)</Typography>
            <FormControl className="form-tf width-200 mt-2 mabo-2">
              <InputLabel htmlFor="standard-adornment-verif-code">Code de vérification</InputLabel>
              <Input
                id="standard-adornment-verif-code"
                name="verifCode"
                type='text'
                value={this.state.verifCode}
                onChange={this.handleChangeField}
              />
              </FormControl>
            </div>
          ) 
        }
        
        return jsxElt;
      case 2:
        jsxElt.push(
          <div key={"creact2"}>
            <FormControl className="form-tf width_51 mabo-2">
                <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                <Input
                  id="standard-adornment-email"
                  name="email"
                  type='text'
                  value={this.state.email}
                  onChange={this.handleChangeField}
                />
            </FormControl>
            <FormControl className="form-tf width_51 mb-2 mabo-2">
                <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                <Input
                  id="standard-adornment-password"
                  name="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={this.handleChangeField}
                  autoComplete='off'
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    }
                    />
              </FormControl>
            </div>
        )
        return jsxElt;
      default:
        return 'Unknown step';
    }
  }

    render() {
        return (
          <Fragment>
          <div className="">
          <Stepper activeStep={this.state.activeStep} orientation="vertical">
            {this.getSteps().map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {this.getStepContent(index)}
                  <div className="">
                    <div>
                      {/* <Button
                        disabled={this.state.activeStep === 0}
                        onClick={this.handleBack}
                        className=""
                      >
                        Précédent
                      </Button> */}
                      {this.state.loading ? (
                        <CircularProgress className="pressed_button_loader"/>
                      ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className=""
                      >
                        {this.state.activeStep === this.getSteps().length - 1 ? 'Créer' : 'Suivant'}
                      </Button>
                      )}
                      
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal"
            open={this.state.modal_open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.modal_open}>
            <div className="paper">
                <h2 id="transition-modal-title">{this.state.modal_title}</h2>
                <p id="transition-modal-description">{this.state.modal_text}</p>
              </div>
            </Fade>
          </Modal>

        </Fragment>
        )
    }
}

export default Register
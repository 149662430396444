import React, { Component } from 'react'
import Page from './Components/Page'

import './App.scss'

class App extends Component {
  render(){
    return (
      <Page />
    )
  }
}

export default App

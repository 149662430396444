import React, { Component } from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MailOutline from '@material-ui/icons/MailOutline';
import Lock from '@material-ui/icons/Lock';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { Fragment } from 'react';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AuthService from "../services/auth-service";
import SimplusButton from './SimplusButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid'
import ParameterService from '../services/param-service'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

class Parameter extends Component {
  state = {
    expanded:false,
    open:false,
    email:'',
    loading_email: false,
    loading_password: false,
    old_pass:'',
    new_pass:'',
    conf_pass:'',
    modal_open:false,
    modal_title:'',
    modal_text: ''
  }

  setExpanded = (panel) => {
    this.setState({expanded:panel})
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setExpanded(isExpanded ? panel : false);
  };

  handleDisconnect = () => {
    this.setState({open:true})
  }

  handleCloseCancel = () => {
    this.setState({open:false})
  }

  handleCloseOk = () => {
    this.setState({open:false})
    AuthService.logout();
    window.location.reload();
  }

  handleChangeField = (event) => {
    const {name, value} = event.target;
    this.setState( {[name]: value} );
  };
  

  handleClose = () => {
    this.setState({modal_open:false,modal_text:'',modal_title:''})
  };

  handleChangeEmailPressed = () => {
    this.setState({loading_email:true});
    
    ParameterService.changeEmail(this.state.email).then(
      (response) => {
        console.log(response);
        //const data = response.data;
        if(response.data.error){
          this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
        }
        else {
          this.setState({modal_open:true,modal_title:'Email mis à jour',modal_text:'Votre nouvelle adresse email de connexion a bien été enregistrée.'})
          this.setState({email: ''});
        }
        this.setState({loading_email: false});
       });
  }

  handleChangePasswordPressed= () => {
    this.setState({loading_password:true});
    ParameterService.changePassword(this.state.old_pass,this.state.new_pass,this.state.conf_pass).then(
      (response) => {
        //const data = response.data;
        if(response.data.error){
          this.setState({modal_open:true,modal_title:'Erreur',modal_text:response.data.error})
        }
        else {
          this.setState({modal_open:true,modal_title:'Mot de passe mis à jour',modal_text:'Votre nouveau mot de passe a bien été enregistré.'})
          this.setState({old_pass:'', new_pass:'',conf_pass:''});
        }
        this.setState({loading_password: false});
       });
  }


    render() {
        return (
          <Fragment>

            <Accordion expanded={this.state.expanded === "email"} onChange={this.handleChange("email")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="bh-content"
                id="bh-header"
              >
                <Typography className="param_menu">
                  <MailOutline className="button_ico"/>Modifier mon email de connexion
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pb-5">
              <form>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={6}>
                  <Grid item xs={12} md={12}>
                    <Typography className="change_email_expl">Vous allez modifier votre email de connexion qui vous permet de vous connecter à l'application</Typography>
                  </Grid>
                  <Grid item xs={false} sm={false} md={2} className="hidden_on_mobile" />
                  <Grid item xs={12} sm={12} md={5}>
                      <FormControl className="form-tf">
                        <InputLabel htmlFor="standard-adornment-email">Nouvel email</InputLabel>
                        <Input
                          id="standard-adornment-email"
                          name="email"
                          type='text'
                          value={this.state.email}
                          onChange={this.handleChangeField}
                        />
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <SimplusButton classNameAdd="btn_change" button_label="Modifier" action_onclick={this.handleChangeEmailPressed} mustReinit={!this.state.loading_email}/>
                  </Grid>
                  <Grid item xs={false} sm={false} md={2} className="hidden_on_mobile"/>
                </Grid>
              </form>
              </AccordionDetails>
            </Accordion>


            <Accordion expanded={this.state.expanded === "pass"} onChange={this.handleChange("pass")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="bh-content"
                id="bh-header"
              >
                <Typography className="param_menu">
                  <Lock className="button_ico"/>Modifier mon mot de passe
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pb-5">
                <form>
                  <Grid container direction="row" justify="center" alignItems="flex-start" spacing={6}>
                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl className="form-tf">
                        <InputLabel htmlFor="standard-adornment-old-pass">Ancien mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-old-pass"
                            name="old_pass"
                            type='password'
                            value={this.state.old_pass}
                            onChange={this.handleChangeField}
                            autoComplete='off'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>

                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>
                    <Grid item xs={12} md={6}>
                      <FormControl className="form-tf">
                        <InputLabel htmlFor="standard-adornment-new-pass">Nouveau mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-new-pass"
                            name="new_pass"
                            type='password'
                            value={this.state.new_pass}
                            onChange={this.handleChangeField}
                            autoComplete='off'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>

                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl className="form-tf">
                        <InputLabel htmlFor="standard-adornment-conf-pass">Confirmer nouveau mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-conf-pass"
                            name="conf_pass"
                            type='password'
                            value={this.state.conf_pass}
                            onChange={this.handleChangeField}
                            autoComplete='off'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={false} sm={false}  md={3} className="hidden_on_mobile"/>

                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>
                    <Grid item xs={12} sm={12} md={6}>
                      <SimplusButton button_label="Modifier" action_onclick={this.handleChangePasswordPressed} mustReinit={!this.state.loading_password}/>
                    </Grid>
                    <Grid item xs={false} sm={false} md={3} className="hidden_on_mobile"/>
                  </Grid>
              </form>
              </AccordionDetails>
            </Accordion>


              <Link className="disconnect_button" onClick={() => {this.handleDisconnect()}} underline='none'>
                <Typography className="param_menu">
                  <PowerSettingsNew className="button_ico"/>Se déconnecter
                </Typography>
              </Link>

              <Dialog
              open={this.state.open}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  &Ecirc;tes-vous sur de vouloir vous déconnecter ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={this.handleCloseCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={this.handleCloseOk} color="primary" autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal"
            open={this.state.modal_open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.modal_open}>
            <div className="paper">
                <h2 id="transition-modal-title">{this.state.modal_title}</h2>
                <p id="transition-modal-description">{this.state.modal_text}</p>
              </div>
            </Fade>
          </Modal>

          </Fragment>
        )
    }
}

export default Parameter
import React, { Component } from 'react'
import SimplusButton from './SimplusButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthService from "../services/auth-service";
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Fragment } from 'react';
import OnboardingService from "../services/onboarding-service";

class LoginForm extends Component {
  
  state = {
    email: '',
    email_forgot:'',
    password: '',
    showPassword: false,
    loading: false,
    message: '',
    forgotPassModal: ''
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState( {[name]: value} );
  };
  
  handleClickShowPassword = () => {
    const st = {...this.state};
    st.showPassword = !st.showPassword ;
    this.setState( st );
  };
  
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  

  handleAuthButtonPressed = (event) => {
    this.setState({
      message: "",
      loading: true
    });

    
    
    AuthService.login(this.state.email,this.state.password).then(
      () => {
        //const user = JSON.parse(localStorage.getItem('user'));
        this.setState({
          loading: false,
          message: ''
        });
        window.location.reload();
      },
      error => {
        var resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          if(resMessage === 'Invalid credentials.')
            resMessage = 'Mauvais email et/ou mot de passe'

        this.setState({
          loading: false,
          message: resMessage
        });

      }
    );
  }

  handlePasswordForgottenPressed = (event) => {
    this.setState({forgotPassModal: true});
  }

  handleClose = () => {
    this.setState({forgotPassModal: false});
  };

  handleCloseSendEmail = () => {

    OnboardingService.forgotPassword(this.state.email_forgot).then(
      (response) => {
        console.log(response)
      }
    );
    this.setState({forgotPassModal: false});
  };

    render() {
        return (
          <Fragment>
          <div className="login_form">
            <h2>Authentification</h2>
            <form>
              <FormControl className="form-tf">
                <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                <Input
                  id="standard-adornment-email"
                  name="email"
                  type='text'
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl className="form-tf mb-2">
                <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                <Input
                  id="standard-adornment-password"
                  name="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              { this.state.message !== '' && (
                <span className="err_msg">{this.state.message}</span>
              )}
              <SimplusButton button_label="CONNEXION" action_onclick={this.handleAuthButtonPressed} mustReinit={!this.state.loading}/>
            </form>
            <p>
            <Link className="cursor_pointer fpass" onClick={this.handlePasswordForgottenPressed} underline='none'>
            Mot de passe oublié ?
            </Link>
            </p>
          </div>
          {this.state.forgotPassModal ? (
            <Dialog open={this.state.forgotPassModal} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Mot de passe oublié ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez renseigner votre email pour recevoir un nouveau mot de passe temporaire (par SMS).
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email_forgot"
            label="Adresse email"
            type="email"
            name="email_forgot"
            onChange={this.handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={this.handleCloseSendEmail} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
          ):""}
        
          </Fragment>
        )
    }
}

export default LoginForm
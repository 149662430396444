import axios from "axios";
import config from "../Config.json";

const API_URL = config.API_URL+"onboarding";

class OnboardingService {

  checkMsisdn(msisdn) {
    return axios
      .post(API_URL+"/check_msisdn", 
        { "msisdn" : msisdn },
        { headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
            }      
        })
        .catch(function (error) {
          return error.response;
      });
  }


  checkSim(last_digits) {
    return axios
      .post(API_URL+"/check_sim", 
        { "last_digits" : last_digits },
        { headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
            }      
        })
        .catch(function (error) {
          return error.response;
      });
  }

  checkVerificationCode(msisdn,sms_code) {
    return axios
      .post(API_URL+"/check_verification_code", 
        { "msisdn" : msisdn, "sms_code":sms_code },
        { headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
            }      
        })
        .catch(function (error) {
          return error.response;
      });
  }

  createCredentials(msisdn,sms_code,email,password) {
    return axios
      .post(API_URL+"/create_credentials", 
        { "msisdn" : msisdn, "sms_code":sms_code, "email":email, "password":password },
        { headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
            }      
        })
        .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch(function (error) {
        return error.response;
    });
  }


  forgotPassword(email) {
    return axios
      .post(API_URL+"/forgot_password", 
        { "email" : email },
        { headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
            }      
        })
        .catch(function (error) {
          return error.response;
      });
  }

  
  
}

export default new OnboardingService();
import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth-service';
import config from "../Config.json";

const API_URL = config.API_URL+'api/userInfo';

class UserService {

    getInfos() {
        return axios.get(API_URL , { headers: authHeader() })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          };
      });
    }

    changeContactInfo(email,phone) {
      return axios.put(API_URL+"/contactInfo" , { "email" : email, "phone":phone.replace(/\s/g,'') }, { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          if(error.response.status === 401){
            AuthService.logout();
            window.location.reload();
          }
        }  
        return error.response;
    });
  }

  changePostalAddress(address,address_comp,zip,city) {
    return axios.put(API_URL+"/address" , { "address" : address, "address_comp":address_comp,"zip":zip,"city":city}, { headers: authHeader() })
    .catch(function (error) {
      if (error.response) {
        if(error.response.status === 401){
          AuthService.logout();
          window.location.reload();
        }
      }  
      return error.response;
  });
} 

  changePaymentInfo(type,param1,param2,param3,param4) {

    var params;
    if(type === 1){//iban
      params = {
        "type": type,
        "iban": param1,
        "bic": param2
      }
    }
    else if(type === 2){//cb
      params = {
        "type": type,
        "numero_cb": param1,
        "mois_fv": param2,
        "annee_fv": param3,
        "numero_cvv": param4
      }
    }

    return axios.put(API_URL+"/paymentInfo" , params , { headers: authHeader() })
    .catch(function (error) {
      if (error.response) {
        if(error.response.status === 401){
          AuthService.logout();
          window.location.reload();
        }
      }  
      return error.response;
  });
  }
}


export default new UserService();

import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth-service';
import config from "../Config.json";

const API_URL = config.API_URL+'api/help';

class HelpService {

    getFaq() {
        return axios.get(API_URL , { headers: authHeader() })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              AuthService.logout();
              window.location.reload();
            }
          };
      });
    }
}

export default new HelpService();
